import Button from '@rio-cloud/rio-uikit/Button';
import { FunctionComponent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ActivationStatus } from '../activation/activation.types';
import { ActivationRequestAcceptConfirmation } from './ActivationRequestAcceptConfirmation';
import { ActivationRequestRejection } from './ActivationRequestRejection';
import { CreateClientConfirmation } from './CreateClientConfirmation';
import { ServiceProvider, ServiceProviderAction } from './serviceProvider.types';
import { DeleteClientConfirmation } from './DeleteClientConfirmation';

type ServiceProviderActionsProps = { serviceProvider: ServiceProvider; allowedActions: ServiceProviderAction[] };

const PendingServiceProviderActions: FunctionComponent<ServiceProviderActionsProps> = ({
    serviceProvider,
    allowedActions,
}) => {
    const [showAccept, setShowAccept] = useState(false);
    const [showReject, setShowReject] = useState(false);

    return (
        <span className="btn-toolbar">
            <Button
                bsStyle={Button.WARNING}
                iconName="rioglyph-thumbs-down"
                onClick={() => setShowReject(true)}
                disabled={!allowedActions.includes(ServiceProviderAction.REQUEST_REJECT)}
            >
                <FormattedMessage id="outboundOrderBook.common.button.reject" />
            </Button>
            <Button
                bsStyle={Button.PRIMARY}
                iconName="rioglyph-thumbs-up"
                onClick={() => setShowAccept(true)}
                disabled={!allowedActions.includes(ServiceProviderAction.REQUEST_ACCEPT)}
            >
                <FormattedMessage id="outboundOrderBook.common.button.accept" />
            </Button>
            <ActivationRequestAcceptConfirmation
                serviceProvider={serviceProvider}
                show={showAccept}
                setShow={setShowAccept}
            />
            <ActivationRequestRejection
                serviceProviderName={serviceProvider.serviceProviderName}
                serviceProviderAccountId={serviceProvider.accountId}
                show={showReject}
                setShow={setShowReject}
            />
        </span>
    );
};

const ActiveServiceProviderActions: FunctionComponent<ServiceProviderActionsProps> = ({
    serviceProvider,
    allowedActions,
}) => {
    const [showCreateClient, setShowCreateClient] = useState(false);
    const [showDeleteClient, setShowDeleteClient] = useState(false);

    const hasClient = Boolean(serviceProvider.clientId);

    if (hasClient) {
        return (
            <span className="btn-toolbar">
                <Button
                    bsStyle={Button.DANGER}
                    iconName="rioglyph-trash"
                    onClick={() => setShowDeleteClient(true)}
                    disabled={!allowedActions.includes(ServiceProviderAction.CLIENT_DELETE)}
                >
                    <FormattedMessage id="outboundOrderBook.serviceProviders.button.deleteClient" />
                </Button>
                <DeleteClientConfirmation
                    serviceProvider={serviceProvider}
                    show={showDeleteClient}
                    setShow={setShowDeleteClient}
                />
            </span>
        );
    } else {
        return (
            <span className="btn-toolbar">
                <Button
                    bsStyle={Button.PRIMARY}
                    iconName="rioglyph-key"
                    onClick={() => setShowCreateClient(true)}
                    disabled={!allowedActions.includes(ServiceProviderAction.CLIENT_CREATE)}
                >
                    <FormattedMessage id="outboundOrderBook.serviceProviders.button.createClient" />
                </Button>
                <CreateClientConfirmation
                    serviceProvider={serviceProvider}
                    show={showCreateClient}
                    setShow={setShowCreateClient}
                />
            </span>
        );
    }
};

export const ServiceProviderActions: FunctionComponent<ServiceProviderActionsProps> = ({
    serviceProvider,
    allowedActions,
}) => {
    if (serviceProvider.activationStatus === ActivationStatus.PENDING) {
        return <PendingServiceProviderActions serviceProvider={serviceProvider} allowedActions={allowedActions} />;
    } else {
        return <ActiveServiceProviderActions serviceProvider={serviceProvider} allowedActions={allowedActions} />;
    }
};
