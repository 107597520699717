import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from '../../../config';
import { decodeResponse, validateResponse } from './responseUtil';
import { prepareHeaders } from './utils';
import { ServiceProviderActivation, ServiceProviderPage } from '../serviceProviders/serviceProvider.types';
import { ServiceProviderPageCodec } from './types/serviceProviderApi.types';
import { mapToApiServiceProviderActivation, mapToServiceProvidersPage } from './mappers/serviceProviderMapper';

export const SERVICE_PROVIDERS_PATH = 'service-providers';
export const ACTIVATIONS_PATH = 'activations';
export const CLIENTS_PATH = 'clients';

type PutServiceProviderActivationProps = {
    serviceProviderActivation: ServiceProviderActivation;
};

type DeleteServiceProviderProps = {
    accountId: string;
};

type PostCreateServiceProviderClientProps = {
    accountId: string;
};

type DeleteServiceProviderClientProps = {
    accountId: string;
    clientId: string;
};

const serviceProviderTag = 'ServiceProviders';

export const serviceProviderApi = createApi({
    reducerPath: 'serviceProviderApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config.backend.FINISHED_VEHICLES_SERVICE,
        prepareHeaders,
    }),
    tagTypes: [serviceProviderTag],
    endpoints: (builder) => ({
        getServiceProviders: builder.query<ServiceProviderPage, void>({
            query: () => ({
                url: SERVICE_PROVIDERS_PATH,
                validateStatus: validateResponse(ServiceProviderPageCodec),
            }),
            transformResponse: decodeResponse(ServiceProviderPageCodec, mapToServiceProvidersPage),
            providesTags: [serviceProviderTag],
        }),
        putServiceProviderActivation: builder.mutation<void, PutServiceProviderActivationProps>({
            query: (props) => ({
                url: `${SERVICE_PROVIDERS_PATH}/${props.serviceProviderActivation.accountId}/${ACTIVATIONS_PATH}`,
                method: 'PUT',
                body: mapToApiServiceProviderActivation(props.serviceProviderActivation),
            }),
            invalidatesTags: [serviceProviderTag],
        }),
        deleteServiceProvider: builder.mutation<void, DeleteServiceProviderProps>({
            query: (props) => ({
                url: `${SERVICE_PROVIDERS_PATH}/${props.accountId}`,
                method: 'DELETE',
                body: true,
            }),
            invalidatesTags: [serviceProviderTag],
        }),
        postCreateServiceProviderClient: builder.mutation<void, PostCreateServiceProviderClientProps>({
            query: (props) => ({
                url: `${SERVICE_PROVIDERS_PATH}/${props.accountId}/${CLIENTS_PATH}`,
                method: 'POST',
                body: true,
            }),
            invalidatesTags: [serviceProviderTag],
        }),
        deleteServiceProviderClient: builder.mutation<void, DeleteServiceProviderClientProps>({
            query: (props) => ({
                url: `${SERVICE_PROVIDERS_PATH}/${props.accountId}/${CLIENTS_PATH}/${props.clientId}`,
                method: 'DELETE',
                body: true,
            }),
            invalidatesTags: [serviceProviderTag],
        }),
    }),
});

export const {
    useGetServiceProvidersQuery,
    usePutServiceProviderActivationMutation,
    useDeleteServiceProviderMutation,
    usePostCreateServiceProviderClientMutation,
    useDeleteServiceProviderClientMutation,
} = serviceProviderApi;
