import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';
import Dialog from '@rio-cloud/rio-uikit/Dialog';
import RadioButton from '@rio-cloud/rio-uikit/lib/es/RadioButton';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ActivationStatus } from '../activation/activation.types';
import { usePutServiceProviderActivationMutation } from '../api/serviceProviderApi';
import { handleQueryError } from '../notifications/ErrorNotification';
import { showSuccessNotification } from '../notifications/SuccessNotification';
import { ConfirmButtonText } from '../sharedComponents/ConfirmButtonText';
import { ServiceProvider, ServiceProviderActivation } from './serviceProvider.types';

export const ActivationRequestAcceptConfirmation = ({
    serviceProvider,
    show,
    setShow,
}: {
    serviceProvider: ServiceProvider;
    show: boolean;
    setShow: (value: boolean) => void;
}) => {
    const [serviceProviderId, setServiceProviderId] = useState(serviceProvider.serviceProviderId);
    const [apiAccessGranted, setApiAccessGranted] = useState(false);

    const [activateServiceProvider, activationRequest] = usePutServiceProviderActivationMutation();
    const serviceProviderName = serviceProvider.serviceProviderName;

    const handleOnClick = async () => {
        const serviceProviderActivation: ServiceProviderActivation = {
            activatedAt: new Date(),
            accountId: serviceProvider.accountId,
            activationStatus: ActivationStatus.ACTIVE,
            serviceProviderId: serviceProviderId.trim(),
            apiAccessGranted,
        };
        setShow(false);
        const result = await activateServiceProvider({
            serviceProviderActivation,
        });
        if (result.hasOwnProperty('error')) {
            handleQueryError((result as { error: FetchBaseQueryError | SerializedError }).error);
        } else {
            showSuccessNotification('outboundOrderBook.serviceProviders.activationRequestAccept.success');
        }
    };

    const apiAccessGrantInput = serviceProvider.apiAccessRequested && (
        <div className="margin-y-2">
            <div>
                <RadioButton
                    name="apiAccessGranted"
                    value
                    checked={apiAccessGranted}
                    onChange={() => setApiAccessGranted(true)}
                >
                    <FormattedMessage id="outboundOrderBook.serviceProviders.activationRequestAccept.interfaceAccessGranted" />
                </RadioButton>
            </div>
            <div>
                <RadioButton
                    name="apiAccessGranted"
                    value={false}
                    checked={!apiAccessGranted}
                    onChange={() => setApiAccessGranted(false)}
                >
                    <FormattedMessage id="outboundOrderBook.serviceProviders.activationRequestAccept.interfaceAccessDeclined" />
                </RadioButton>
            </div>
        </div>
    );

    return (
        <ConfirmationDialog
            show={show}
            title={<FormattedMessage id="outboundOrderBook.serviceProviders.activationRequestAccept.title" />}
            content={
                <>
                    <div className="form-group ">
                        <label htmlFor="inputLspId">
                            <FormattedMessage id="outboundOrderBook.serviceProviders.activationRequestAccept.serviceProviderId.label" />
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="inputLspId"
                            placeholder="Id"
                            value={serviceProviderId}
                            onChange={(event) => {
                                setServiceProviderId(event.currentTarget.value);
                            }}
                        />
                    </div>

                    {apiAccessGrantInput}

                    <div>
                        <FormattedMessage
                            id="outboundOrderBook.serviceProviders.activationRequestAccept.text"
                            values={{
                                serviceProvider: <strong>{serviceProviderName}</strong>,
                            }}
                        />
                    </div>
                </>
            }
            bsSize={Dialog.SIZE_SM}
            onClickConfirm={handleOnClick}
            onClickCancel={() => setShow(false)}
            cancelButtonText={<FormattedMessage id="outboundOrderBook.common.button.cancel" />}
            confirmButtonText={
                <ConfirmButtonText
                    showSpinner={activationRequest.isLoading}
                    labelId="outboundOrderBook.common.button.accept"
                />
            }
            useOverflow
        />
    );
};
