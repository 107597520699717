import { ServiceProviderColumn, serviceProviderColumnDefinition } from './ServiceProviderTableColumns';
import React, { ReactNode } from 'react';
import { ServiceProvider, ServiceProviderAction, Tenant } from './serviceProvider.types';
import { formatDate } from 'date-fns';
import { NoValue } from '../sharedComponents/NoValue';
import { ServiceProviderActions } from './ServiceProviderActions';
import { FormattedMessage } from 'react-intl';
import { StatusLabel } from '../sharedComponents/StatusLabel';

const TenantsList = (props: { tenants: Tenant[] }) => {
    return (
        <>
            {props.tenants.length === 0 ? (
                <NoValue />
            ) : (
                props.tenants.map((tenant) => (
                    <div key={tenant}>
                        <span>{tenant}</span>
                    </div>
                ))
            )}
        </>
    );
};

type ServiceProvidersTableRowProps = { serviceProvider: ServiceProvider; allowedActions: ServiceProviderAction[] };

export const ServiceProvidersTableRow = (props: ServiceProvidersTableRowProps) => {
    const renderColumnCell = (column: ServiceProviderColumn, rowData: ServiceProvider): string | ReactNode => {
        switch (column) {
            case ServiceProviderColumn.REGISTRATION_DATE:
                return formatDate(rowData.registrationDate, 'dd.MM.yyyy');
            case ServiceProviderColumn.ACTIVATION_DATE:
                return rowData.activationDate ? formatDate(rowData.activationDate, 'dd.MM.yyyy') : <NoValue />;
            case ServiceProviderColumn.API_ACCESS:
                return rowData.apiAccessRequested ? (
                    <FormattedMessage id="outboundOrderBook.common.yes" />
                ) : (
                    <FormattedMessage id="outboundOrderBook.common.no" />
                );
            case ServiceProviderColumn.ACTIVATION_STATUS:
                return <StatusLabel status={rowData.activationStatus} />;
            case ServiceProviderColumn.SERVICE_PROVIDER_ID:
                return rowData.serviceProviderId;
            case ServiceProviderColumn.SERVICE_PROVIDER_NAME:
                return rowData.serviceProviderName;
            case ServiceProviderColumn.DUNS:
                return rowData.duns;
            case ServiceProviderColumn.ACCOUNT_ID:
                return rowData.accountId;
            case ServiceProviderColumn.TENANTS:
                return <TenantsList tenants={rowData.tenants} />;
            case ServiceProviderColumn.CLIENT_ID:
                return rowData.clientId ?? <NoValue />;
            case ServiceProviderColumn.ACTION:
                return <ServiceProviderActions serviceProvider={rowData} allowedActions={props.allowedActions} />;
        }
    };

    return (
        <tr key={props.serviceProvider.accountId}>
            {serviceProviderColumnDefinition.map((def) => (
                <td key={def.column} data-field={def.column}>
                    <span>{renderColumnCell(def.column, props.serviceProvider)}</span>
                </td>
            ))}
        </tr>
    );
};
