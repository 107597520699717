import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';
import Dialog from '@rio-cloud/rio-uikit/Dialog';
import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { usePostCreateServiceProviderClientMutation } from '../api/serviceProviderApi';
import { handleQueryError } from '../notifications/ErrorNotification';
import { showSuccessNotification } from '../notifications/SuccessNotification';
import { ConfirmButtonText } from '../sharedComponents/ConfirmButtonText';
import { ServiceProvider } from './serviceProvider.types';

type CreateClientConfirmationProps = {
    serviceProvider: ServiceProvider;
    show: boolean;
    setShow: (value: boolean) => void;
};

export const CreateClientConfirmation: FunctionComponent<CreateClientConfirmationProps> = ({
    serviceProvider,
    show,
    setShow,
}) => {
    const [createClient, createClientRequest] = usePostCreateServiceProviderClientMutation();

    const { serviceProviderName, accountId } = serviceProvider;

    const handleOnClick = async () => {
        setShow(false);
        const result = await createClient({ accountId });
        if (result.hasOwnProperty('error')) {
            handleQueryError((result as { error: FetchBaseQueryError | SerializedError }).error);
        } else {
            showSuccessNotification('outboundOrderBook.serviceProviders.createClient.success');
        }
    };

    return (
        <ConfirmationDialog
            show={show}
            title={<FormattedMessage id="outboundOrderBook.serviceProviders.createClient.title" />}
            content={
                <div>
                    <FormattedMessage
                        id="outboundOrderBook.serviceProviders.createClient.text"
                        values={{
                            serviceProviderName: <strong>{serviceProviderName}</strong>,
                        }}
                    />
                </div>
            }
            bsSize={Dialog.SIZE_SM}
            onClickConfirm={handleOnClick}
            onClickCancel={() => setShow(false)}
            cancelButtonText={<FormattedMessage id="outboundOrderBook.common.button.cancel" />}
            confirmButtonText={
                <ConfirmButtonText
                    showSpinner={createClientRequest.isLoading}
                    labelId="outboundOrderBook.serviceProviders.button.createClient"
                />
            }
            useOverflow
        />
    );
};
