import {
    ApiServiceProvider,
    ApiServiceProviderActivation,
    ApiServiceProviderPage,
    ApiTenant,
} from '../types/serviceProviderApi.types';
import {
    ServiceProvider,
    ServiceProviderAction,
    ServiceProviderActivation,
    ServiceProviderPage,
    Tenant,
} from '../../serviceProviders/serviceProvider.types';
import { parseStringDate } from '../../sharedComponents/dateHelper';
import { mapToActivationStatus, mapToSupplier } from './activationMapper';
import { ActivationStatus } from '../../activation/activation.types';
import { ApiActivationStatus } from '../types/activationsApi.types';

export const mapToApiActivationStatus = (activationStatus: ActivationStatus): ApiActivationStatus => {
    switch (activationStatus) {
        case ActivationStatus.ACTIVE:
            return ApiActivationStatus.ACTIVE;
        case ActivationStatus.PENDING:
            return ApiActivationStatus.PENDING;
    }
};

export const mapToApiServiceProviderActivation = (
    serviceProviderActivation: ServiceProviderActivation,
): ApiServiceProviderActivation => {
    return {
        activated_at: serviceProviderActivation.activatedAt.toISOString(),
        activation_status: mapToApiActivationStatus(serviceProviderActivation.activationStatus),
        service_provider_identification: serviceProviderActivation.serviceProviderId,
        interface_access_granted: serviceProviderActivation.apiAccessGranted,
    };
};

export const mapToTenant = (apiTenant: ApiTenant): Tenant => {
    switch (apiTenant) {
        case ApiTenant.LSP_TEST:
            return Tenant.LSP_TEST;
        case ApiTenant.VW_PROD:
            return Tenant.VW_PROD;
        case ApiTenant.VW_QA:
            return Tenant.VW_QA;
        case ApiTenant.VW_TEST:
            return Tenant.VW_TEST;
    }
};

export const mapToServiceProvider = (apiServiceProvider: ApiServiceProvider): ServiceProvider => {
    return {
        accountId: apiServiceProvider.account_id,
        supplier: mapToSupplier(apiServiceProvider.supplier),
        serviceProviderName: apiServiceProvider.service_provider_name,
        serviceProviderId: apiServiceProvider.service_provider_identification,
        duns: apiServiceProvider.duns,
        registrationDate: parseStringDate(apiServiceProvider.account_id, apiServiceProvider.activation_requested_at),
        activationDate:
            apiServiceProvider.activated_at !== undefined
                ? parseStringDate(apiServiceProvider.account_id, apiServiceProvider.activated_at)
                : undefined,
        apiAccessRequested: apiServiceProvider.interface_access_requested,
        activationStatus: mapToActivationStatus(apiServiceProvider.activation_status),
        tenants: apiServiceProvider.tenants.map((tenant) => mapToTenant(tenant)),
        clientId: apiServiceProvider.client_id,
    };
};

const mapToServiceProviderActions = (apiActions: string[]): ServiceProviderAction[] =>
    apiActions
        .map((apiAction) => {
            switch (apiAction) {
                case 'request.accept':
                    return ServiceProviderAction.REQUEST_ACCEPT;
                case 'request.reject':
                    return ServiceProviderAction.REQUEST_REJECT;
                case 'client.create':
                    return ServiceProviderAction.CLIENT_CREATE;
                case 'client.delete':
                    return ServiceProviderAction.CLIENT_DELETE;
            }
        })
        .filter((action) => typeof action !== 'undefined');

export const mapToServiceProvidersPage = (apiServiceProviderPage: ApiServiceProviderPage): ServiceProviderPage => {
    const serviceProviders = apiServiceProviderPage.service_providers.map(
        (apiServiceProvider: ApiServiceProvider): ServiceProvider => mapToServiceProvider(apiServiceProvider),
    );

    const allowedActions = mapToServiceProviderActions(apiServiceProviderPage.allowed_actions);

    return { serviceProviders, allowedActions };
};
